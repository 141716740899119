import { useFormik } from "formik";
import React from "react";
import { ResetPasswordSchema } from "./validation_schema/auth.schema";
import InputBodyColsTwo from "../../components/common/core/InputBodyColsTwo";
import PasswordInputField from "../../components/common/core/PasswordInputField";
import i18n from "../../i18n";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getAuth, updatePassword } from "firebase/auth";
import con from "../../config/firebaseConfig";
import { toast } from "react-toastify";
import axios from "axios";
import { UrlMap } from "../../data/constants/URLMAP";

function ResetPassword() {
  const urlString = useLocation()?.search?.split("&");
  const navigate = useNavigate();

  const code = urlString[1]?.split("oobCode=")[1];
  const apikey = urlString[2]?.split("apiKey=")[1];

  const handleSubmit = (values) => {
    const obj = {
      oobCode: code,
      newPassword: values.newPassword,
    };

    axios.post(`https://www.googleapis.com/identitytoolkit/v3/relyingparty/resetPassword?key=${apikey}`, obj).then(() => {
      toast.success(i18n.t("RESET_PASSWORD.SUCCESS_RESET_PASSWORD"));
      navigate(UrlMap.login);
    }).catch((error) => {
      console.log(error, "error");
      toast.error(error);
    });
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className=" backdrop-blur-xl backdrop-sepia-0 w-[350px] h-auto border-2 border-[orange] rounded-2xl ">
      <div className="flex flex-col gap-3 p-10">
        <h1 className="text-white text-4xl text-center">{i18n.t("RESET_PASSWORD.SECTION_TITLE")}</h1>
        <div>
          <h1 className=" text-2xl font-normal ">{i18n.t("RESET_PASSWORD.SECTION_TEXT")}</h1>
        </div>

        <InputBodyColsTwo>
          <PasswordInputField
            fieldName={"newPassword"}
            fieldPlaceholder={i18n.t("RESET_PASSWORD.PASSWORD_PLACEHOLDER")}
            handleOnChange={formik.handleChange}
            values={formik.values.newPassword}
            errMsg={formik.errors.newPassword}
            className={"pb-5"}
          />

          <PasswordInputField
            fieldName={"confirmPassword"}
            fieldPlaceholder={i18n.t("RESET_PASSWORD.CONFIRM_PASSWORD")}
            handleOnChange={formik.handleChange}
            values={formik.values.confirmPassword}
            errMsg={formik.errors.confirmPassword}
          />
        </InputBodyColsTwo>
        <div className="pt-4">
          <button type="button" className="auth-btn" onClick={formik.handleSubmit}>
            {i18n.t("RESET_PASSWORD.SUBMIT_BTN")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
