import { createSlice } from "@reduxjs/toolkit"

import { studentArray } from "../../constant/studentArray";
import { studentSliceConst } from "../../constant/SliceConstants";


const initialState = {
    studentsLoading: false,
    students: studentArray,
    studentsError: '',
    
    updateStudentStatusLoading: false,
    updateStudent: '',
    updateStudentStatusError:''
}

export const studentSlice = createSlice({
    name: studentSliceConst.STUDENT_SLICE,
    initialState: initialState,
    reducers: {
        getStudentsLoading: (state) => {
            state.studentsLoading = true;
        },
        getStudents: (state, payload) => {
            state.studentsLoading = false;
            state.students=payload
        },
        getStudentsError: (state, payload) => {
            state.studentsLoading = false;
            state.studentsError=payload
        },

        updateStudentStatusRequest: (state) => {
            state.updateStudentStatusLoading = true;
        },
        updateStudentStatus: (state, action) => {
            console.log("dispatched");
            
            state.updateStudentStatusLoading = false;
            state.students = action.payload; 
        },
        updateStudentError: (state, action) => {
            state.updateStudentStatusLoading = false;
            state.updateStudentStatusError = action
        }
    }
})

export const { getStudentsLoading, getStudents, getStudentsError,
    updateStudentStatusRequest, updateStudentStatus, updateStudentError
 } = studentSlice.actions

export default studentSlice.reducer