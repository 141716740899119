export const authSliceConst = {
    AUTH_SLICE:'auth'
}

export const profilSliceConst = {
    PROFILE_SLICE:'profil'
}

export const studentSliceConst = {
    STUDENT_SLICE:'students'
}