import { AppTitle } from "../constant";
import i18n from "../i18n";

 function updateAppTitle(pathname) {
    window.scrollTo(0, 0);
    // const currentPage = startCase(pathname).replaceAll('-', ' ');
    document.title = `${AppTitle}`;
}

 function changeAppLanguage(lang = 'en') {
    i18n.changeLanguage(lang);
    window.location.reload();
}

function isMobileDevice() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

export { isMobileDevice, changeAppLanguage, updateAppTitle }