export enum PageName {
  Home = "Home",
  Present = "Present",
  Absent="Absent",

  /*Auth pages start ##### */
  Login = "login",
  SignUp = "SignUp",
  ForgotPassword = "ForgotPassword",
  ResetPassword = "ResetPassword",
  /*Auth pages end ##### */
}
