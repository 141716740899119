function InputBodyColsTwo({children}){
    return(
        <>
            <div className="flex gap-5 flex-col	">
                {children}
            </div>
        </>
    )
}

export default InputBodyColsTwo;