export const localStorageUtil = {
    getItem(key) {
        return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null
    },
    setItem(key, value) {
        return localStorage.setItem(key, JSON.stringify(value))
    },
    removeItem(key) {
        return localStorage.removeItem(key)
    },
    clearLocalStorage() {
        return localStorage.clear()
    }

}