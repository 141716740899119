import { createSlice } from "@reduxjs/toolkit"
import { profilSliceConst } from "../../data/constants/SliceConstants"

const initialState = {
    forgotPasswordLoading: false,
    forgotPasswordMessage: '',
    forgotPasswordError: '',

    resetPasswordLeading: false,
    resetPasswordMessage: '',
    resetPasswordError: '',
}

const profileSlice = createSlice({
    name: profilSliceConst.PROFILE_SLICE,
    initialState,
    reducers: {
        forgotPasswordRequest(state) {
            state.forgotPasswordLoading = true;
        },
        forgotPasswordSuccess(state, action) {
            state.forgotPasswordLoading = false;
            state.forgotPasswordMessage = action.payload;
        },
        forgotPasswordError(state, action) {
            state.forgotPasswordLoading = false;
            state.forgotPasswordError = action.payload;
        },

        resetPasswordRequest(state) {
            state.resetPasswordLeading = true;
        },
        resetPasswordSuccess(state, action) {
            state.resetPasswordLeading = false;
            state.resetPasswordMessage = action.payload;
        },
        resetPasswordError(state, action) {
            state.resetPasswordLeading = false;
            state.forgotPasswordError = action.payload;
        },

        resetState(state) {
            state.resetPasswordMessage = '';
            state.resetPasswordError = '';
            state.forgotPasswordMessage = '';
            state.forgotPasswordError = '';
        }
    }
})

export const { forgotPasswordRequest, forgotPasswordSuccess, forgotPasswordError,
    resetPasswordRequest, resetPasswordSuccess, resetPasswordError,
    resetState
} = profileSlice.actions

export default profileSlice.reducer