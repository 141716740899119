import { useFormik } from "formik";
import React from "react";
import { ForgotPasswordSchema, ResetPasswordSchema } from "./validation_schema/auth.schema";
import InputField from "../../components/common/core/InputField";
import { useDispatch } from "react-redux";
import { forgotPassword, resetPassword } from "../../store/middleware/profile.middleware";
import { useTranslation } from "react-i18next";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import con from "../../config/firebaseConfig";
import { toast } from "react-toastify";

function ForgotPassword() {
  const { t } = useTranslation();
  const auth = getAuth(con);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    sendPasswordResetEmail(auth, values.email)
      .then(() => {
        toast.success(t("FORGOT_PASSWORD.PASSWORD_RESET_EMAIL_SENT"));
      })
      .catch((error) => {
        console.log(error, "error");
      });
    // dispatch(resetPassword(values));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className=" backdrop-blur-xl backdrop-sepia-0 w-[350px] h-[300px] border-2 border-[orange] rounded-2xl ">
      <div className="flex flex-col gap-3 p-10">
        <h1 className="text-white text-4xl text-center">{t("FORGOT_PASSWORD.SECTION_TITLE")}</h1>
        <div>
          <h1 className="text-2xl font-normal ">{t("FORGOT_PASSWORD.SECTION_TEXT")}</h1>
        </div>
        <div className="flex gap-5 flex-col">
          <InputField
            fieldType={"email"}
            fieldName={"email"}
            handleOnChange={formik.handleChange}
            values={formik.values.email}
            fieldPlaceholder={t("FORGOT_PASSWORD.EMAIL_PLACEHOLDER")}
            errMsg={formik.errors.email}
          />
          <div>
            <button type="button" className="auth-btn" onClick={formik.handleSubmit}>
              {t("FORGOT_PASSWORD.SUBMIT_BTN")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
