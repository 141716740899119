import React from "react";
import { useTranslation } from "react-i18next";
import right from "../assets/icons/right.svg";
import cancel from "../assets/icons/cancel.svg";
import { studentStatus } from "../constant";

function StudentInfo({ studentDetails, handleClick, studentsAttendance }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center h-[220px] bg-white rounded-xl shadow-sm w-[280px] relative mb-[50px]">
      <div className="absolute top-[-40px] border-white border-[5px] rounded-xl">
        <img loading="lazy" src={studentDetails.img} alt="kid" width={"210px"} className="rounded-xl" />
      </div>
      <div className="absolute bottom-3 flex flex-col gap-3 w-[90%]">
        <p className=" text-[14px] text-center">
          {t("HOME.STUDENT_NAME")} : <span className="text-wrap">{studentDetails.name}</span>
        </p>
        <div className="flex gap-2">
          {!studentsAttendance && (
            <button className="update-attendance-btn px-5 py-1 text-sm text-white rounded-xl w-[100%]" onClick={() => handleClick(studentDetails)}>
              {t("HOME.UPDATE_ATTENDANCE_BTN")}
            </button>
          )}

          {studentsAttendance === studentStatus.PRESENT && (
            <button
              className="flex gap-3.5 justify-center w-[100%] text-m font-bold leading-relaxed text-white whitespace-nowrap present-bg rounded-lg max-md:px-5"
              onClick={() => handleClick(studentDetails)}
            >
              <div>{t("HOME.PRESENT_BTN")}</div>
              <img loading="lazy" src={right} alt="right" className="object-contain shrink-0 my-auto aspect-square w-[15px]" />
            </button>
          )}
          {studentsAttendance === studentStatus.ABSENT && (
            <button
              className="flex gap-3.5 justify-center w-[100%]  text-m font-bold leading-relaxed text-white whitespace-nowrap absent-bg rounded-lg max-md:px-5"
              onClick={() => handleClick(studentDetails)}
            >
              <div>{t("HOME.ABSENT_BTN")}</div>
              <img loading="lazy" src={cancel} alt="cancel" className="object-contain shrink-0 my-auto aspect-square w-[15px]" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default StudentInfo;
