import React from "react";
import userIcon from "../../../assets/icons/user_icon.svg";
import notification from "../../../assets/icons/notifications.svg";
import messages from "../../../assets/icons/messages.svg";
import search from "../../../assets/icons/search.svg";
import { t } from "i18next";
import { studentStatus } from "../../../constant";
import i18n from "../../../i18n";
import { useLocation } from "react-router-dom";

function Navbar({ handleStudentList, handleSearch }) {
  const pathname = useLocation().pathname;
  const { language } = i18n;

  const path = pathname.split("/")[1];

  return (
    <div className="navbar">
      <div className="flex flex-wrap gap-5 justify-between ml-2.5 w-full max-md:max-w-full mobile-btn">
        <div className="flex ">
          <button
            className={`flex gap-3.5 px-8 py-1 text-xl font-extrabold leading-relaxed text-white whitespace-nowrap   max-md:px-5 ${path === studentStatus.PRESENT ? "present-bg" : "primary-bg"} ${
              language === "en" ? "rounded-l-lg" : "rounded-r-lg"
            }`}
            onClick={() => handleStudentList(studentStatus.PRESENT)}
          >
            <div>{t("HOME.PRESENT_BTN")}</div>
            <img loading="lazy" src={userIcon} alt="user-icon" className="object-contain shrink-0 my-auto aspect-square w-[15px]" />
          </button>
          <hr className="bg-white w-[1px] rotate-90" />
          <button
            className={`flex gap-3.5 px-8 py-1 text-xl font-extrabold leading-relaxed text-white whitespace-nowrap max-md:px-5 ${path === studentStatus.ABSENT ? "absent-bg" : "primary-bg"} ${
              language === "en" ? "rounded-r-lg" : "rounded-l-lg"
            }`}
            onClick={() => handleStudentList(studentStatus.ABSENT)}
          >
            <div>{t("HOME.ABSENT_BTN")}</div>
            <img loading="lazy" src={userIcon} alt="user-icon" className="object-contain shrink-0 my-auto aspect-square w-[15px]" />
          </button>
        </div>
        <div className="wrapper ">
          <div className="search ">
            <input type="text" name="search" className="search_field" placeholder={t("HOME.SEARCH_PLACEHOLDER")} onChange={handleSearch} />
            <img loading="lazy" src={search} alt="search" className={`absolute top-[12px] ${language === "en" ? "right-[8px]" : "left-[8px]"} `} />
          </div>
          <div className="flex ps-5 gap-2 mobile-none">
            <div className="icon-box">
              <img loading="lazy" src={messages} alt="messages" className="cursor-pointer" />
            </div>
            <div className="icon-box">
              <img loading="lazy" src={notification} alt="notification" className="cursor-pointer" />
            </div>
          </div>
        </div>
      </div>

      {/* {language === "he" && (
        <div className="flex flex-wrap gap-5 justify-between ml-2.5 w-full max-md:max-w-full">
          <button
            className={`flex gap-3.5 px-8 py-1 text-xl font-extrabold leading-relaxed text-white whitespace-nowrap  rounded-lg max-md:px-5 ${
              studentsAttendance === studentStatus.PRESENT ? "present-bg" : "primary-bg"
            }`}
            onClick={() => handleStudentList(studentStatus.PRESENT)}
          >
            <div>{t('HOME.PRESENT_BTN')}</div>
            <img loading="lazy" src={userIcon} alt="user-icon" className="object-contain shrink-0 my-auto aspect-square w-[15px]" />
          </button>
          <div className="wrapper">
            <div className="search ">
              <input type="text" name="search" className="search_field" placeholder={t("HOME.SEARCH_PLACEHOLDER")} />
              <img loading="lazy" src={search} alt="search" className="absolute top-[12px] left-[8px]" />
            </div>
            <div className="flex ps-5 gap-2">
              <div className="icon-box">
                <img loading="lazy" src={messages} alt="messages" className="cursor-pointer" />
              </div>
              <div className="icon-box">
                <img loading="lazy" src={notification} alt="notification" className="cursor-pointer" />
              </div>
            </div>
          </div>
          <button
            className={`flex gap-3.5 px-8 py-1 text-xl font-extrabold leading-relaxed text-white whitespace-nowrap rounded-lg max-md:px-5 ${
              studentsAttendance === studentStatus.ABSENT ? "absent-bg" : "primary-bg"
            }`}
            onClick={() => handleStudentList(studentStatus.ABSENT)}
          >
            <div>{t('HOME.ABSENT_BTN')}</div>
            <img loading="lazy" src={userIcon} alt="user-icon" className="object-contain shrink-0 my-auto aspect-square w-[15px]" />
          </button>
        </div>
      )} */}
    </div>
  );
}

export default Navbar;
