import kid1 from '../assets/images/kid1.jpg'
import kid2 from '../assets/images/kid2.jpg'
import kid3 from '../assets/images/kid3.jpg'
import kid4 from '../assets/images/kid4.jpg'
import kid5 from '../assets/images/kid5.jpg'
import kid6 from '../assets/images/kid6.jpg'
import kid7 from '../assets/images/kid7.jpg'
import kid8 from '../assets/images/kid8.jpg'
import kid9 from '../assets/images/kid9.jpg'
import kid10 from '../assets/images/kid10.jpg'
import kid11 from '../assets/images/kid11.jpg'
import kid12 from '../assets/images/kid12.jpg'
import kid13 from '../assets/images/kid13.jpg'
import kid14 from '../assets/images/kid14.jpg'

export const studentArray = [
    { id: 1, name: 'David Lavi', img: kid1, status: null },
    { id: 2, name: 'Deshaun McClure', img: kid2, status: null },
    { id: 3, name: 'Hadley Urbina', img: kid3, status: null },
    { id: 4, name: 'Beyonce Blakely', img: kid4, status: null },
    { id: 5, name: 'Jazlynn Spencer', img: kid5, status: null },
    { id: 6, name: 'Cecilia Krieger', img: kid6, status: null },
    { id: 7, name: 'Camilla Neely', img: kid7, status: null },
    { id: 8, name: 'Syed Currie', img: kid8, status: null },
    { id: 9, name: 'Yamileth Wynn', img: kid9, status: null },
    { id: 10, name: 'Aylin Hinojosa', img: kid10, status: null },
    { id: 11, name: 'Hector Seibert', img: kid11, status: null },
    { id: 12, name: 'Anai Morse', img: kid12, status: null },
    { id: 13, name: 'Brianne Hofmann', img: kid13, status: null },
    { id: 14, name: 'Iman Salter', img: kid14, status: null },
]














