
import UserLayout from "../../components/layouts/UserLayout";
import StudentCard from "../../components/StudentCard";
import StudentListing from "../../components/StudentListing";
import { UrlMap } from "../../data/constants/URLMAP";

export const routes = [
    {
        element: <UserLayout />,
        children: [
            {
                path: UrlMap.Home,
                element: <>

                    <StudentCard />
                </>,
                children: [{
                    path: UrlMap.Present,
                    element: <StudentListing />
                },
                {
                    path: UrlMap.Absent,
                    element: <StudentListing />
                }
                ]
            }
        ]
    }
]