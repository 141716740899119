/* eslint-disable react/prop-types */
import ErrorField from "./ErrorField";

function InputField({
  className,
  fieldType,
  fieldId,
  fieldName,
  fieldLabel,
  fieldPlaceholder,
  isRequired = false,
  isReadOnly = false,
  handleOnChange,
  handleOnBlur,
  values,
  errMsg,
  otherProps = {},
  disabled = false,
  onKeyPress,
}) {
  const errMsgField = errMsg ? <ErrorField errMsg={errMsg} /> : "";

  return (
    <div className="relative flex ">
      {/* <LabelField fieldLabel={fieldLabel} isRequired={isRequired} textCounter={maxLengthOfInput ? `${inputCharCount}/${maxLengthOfInput}` : ""} /> */}
      <input
        className={
          className
            ? `${className} pl-4 border-[1px] rounded-radius_4 leading-[18px] py-3 w-full px-2 text-sm ${errMsg ? "border-red-500" : "primary-border"}`
            : ` pl-4  border-[1px] rounded-radius_4 leading-[18px] py-3 w-full px-2 text-sm ${errMsg ? "border-red-500" : "primary-border"}`
        }
        disabled={disabled}
        id={fieldId}
        name={fieldName}
        type={fieldType}
        readOnly={isReadOnly}
        placeholder={fieldPlaceholder}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        value={values || ""}
        onWheel={(e) => e.currentTarget.blur()}
        onKeyDown={onKeyPress}
      />
      <div className="absolute bottom-[-20px]">{errMsgField}</div>
    </div>
  );
}
export default InputField;
