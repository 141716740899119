export const RouteTitles = {
    '/dashboard': 'Dashboard',
   
    '/': 'Kipikid',
};

export const AppTitle = 'Kipikid';

export const studentStatus = {
    ABSENT: 'absent',
    PRESENT:'present'
}
