import { initializeApp } from "firebase/app";

const firebaseConfig = {
    "apiKey": process.env.REACT_APP_APIKEY,
    "authDomain": process.env.REACT_APP_AUTH_DOMAIN,
    "type": process.env.REACT_APP_TYPE,
    "project_id": process.env.REACT_APP_PROJECT_ID,
    "private_key_id": process.env.REACT_APP_PRIVATE_KEY_ID,
    "private_key": process.env.REACT_APP_PRIVATE_KEY,
    "client_email": process.env.REACT_APP_CLIENT_EMAIL,
    "client_id": process.env.REACT_APP_CLIENT_ID,
    "auth_uri": process.env.REACT_APP_AUTH_URI,
    "token_uri": process.env.REACT_APP_TOKEN_URI,
    "auth_provider_x509_cert_url": process.env.REACT_APP_AUTH_PROVIDER_x509_CERT_URL,
    "client_x509_cert_url": process.env.REACT_APP_CLIENT_x509_CERT_URL,
    "universe_domain": process.env.REACT_APP_UNIVERSE_DOMAIN
};

// Initialize Firebase
const con = initializeApp(firebaseConfig);

export default con