import React from 'react'
import Loader from './Loader';

function SubmitBtn({onSubmit,btnText,isLoading,}) {
  return (
    <button className="auth-btn" disabled={isLoading} type="submit" onClick={onSubmit}>
      {isLoading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        btnText
      )}
    </button>
  );
}

export default SubmitBtn