import axios from "axios"

import { toast } from "react-toastify"
import { localStorageUtil } from "../utils/storage/localStorage.util"
import { localStorageConst } from "../data/constants/LocalStorage.const"

const API_URL = process.env.REACT_APP_API_BASE_URL || 'https://identificationkindergartensdev.azurewebsites.net/api/'

const axiosAPI = axios.create({
    baseURL: API_URL
})

axiosAPI.interceptors.request.use((config) => {
    const token = localStorageUtil.getItem(localStorageConst.AUTH_TOKEN);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, (error) => {
    console.log(error, "Error");
    toast.error(error?.message)
    return Promise.reject(error)
})

axiosAPI.interceptors.response.use((res) => {
    return res
}, (error) => {
    console.log(error, "Error");
    toast.error(error?.message)
    return Promise.reject(error)
})

export const get = async(url,config = {}) => {
    return await axiosAPI.get(url, { ...config });
} 

export const post = async (url,data, config = {}) => {
    return await axiosAPI.post(url,{...data}, { ...config });
} 

export const put = async (url, data, config = {}) => {
    return await axiosAPI.put(url, { ...data }, { ...config });
}

export const del = async (url, config = {}) => {
    return await axiosAPI.del(url, { ...config });
}

export async function postFormData(url, data, config = {}) {
    return axiosAPI.post(url, data, {
        "Content-Type": "multipart/form-data",
        ...config,
    });
}

export async function putFormData(url, data, config = {}) {
    return axiosAPI.put(url, data, {
        "Content-Type": "multipart/form-data",
        ...config,
    });
}