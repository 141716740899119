import React, { useState } from "react";
import home_icon from "../../../assets/icons/home_icon.svg";
import home from "../../../assets/icons/home.svg";
import control from "../../../assets/icons/Control.svg";
import books from "../../../assets/icons/Books.svg";
import history from "../../../assets/icons/history.svg";
import { changeAppLanguage } from "../../../helper";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import sidebar from "../../../assets/icons/sidebar.svg";
import logoutICon from "../../../assets/icons/logout.svg";
import { localStorageUtil } from "../../../utils/storage/localStorage.util";
import { useNavigate } from "react-router-dom";
import { localStorageConst } from "../../../data/constants/LocalStorage.const";
import { getAuth } from "firebase/auth";
import con from "../../../config/firebaseConfig";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/middleware/auth.middleware";
import notification from "../../../assets/icons/notifications.svg";
import messages from "../../../assets/icons/messages.svg";
import Alert from "./Alert";

function SideBar({ handleStudentList }) {
  const { t } = useTranslation();
  const lang = i18n.resolvedLanguage;
  const [showSidebar, setShowSidebar] = useState(false);
  const [showLogoutModal,setShowLogoutModal] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = getAuth(con);

  const handleToggle = () => {
    setShowSidebar(!showSidebar);
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
        dispatch(logout());
        setShowLogoutModal(false);
        navigate("/login");
      });
  };

  const handleLogoutModal = () => {
     setShowLogoutModal(true);
  }

  const closeLogoutModal = () => {
     setShowLogoutModal(false);
  }
  return (
    <>
      <div className="flex flex-col">
        <div className="header-mobile">
          <div className="toggle-wrapper">
            <div className="primary-bg rounded-xl p-2 w-[50px] max-sm:flex hidden" onClick={handleToggle}>
              <img src={sidebar} alt="sidebar" width={"40px"} />
            </div>
            <div className="icon-box desk-none">
              <img loading="lazy" src={messages} alt="messages" className="cursor-pointer" />
            </div>
            <div className="icon-box desk-none">
              <img loading="lazy" src={notification} alt="notification" className="cursor-pointer" />
            </div>
          </div>

          <div className="logo-mobile desk-none">
            <img src={home_icon} onClick={() => handleStudentList("")} className="object-contain self-start aspect-square w-[74px] max-md:-mr-2.5" alt="site-icon" />
          </div>
        </div>

        {showSidebar && (
          <div className=" flex-col justify-between primary-bg rounded-3xl pb-[25%] max-md:pb-24 max-sm:flex hidden mobile-sidebar">
            <div className="flex flex-col items-center pt-1 ">
              {/* <img loading="lazy" onClick={() => handleStudentList("")} src={home_icon} className="object-contain self-start aspect-square w-[74px] max-md:-mr-2.5" alt="site-icon" /> */}

              <img loading="lazy" onClick={() => handleStudentList("")} src={home} className="object-contain mt-4 aspect-square w-[25px]" alt="home-icon" />
              <img loading="lazy" src={control} className="object-contain mt-5 aspect-square w-[25px]" alt="contr-icon" />
              <img loading="lazy" src={books} className="object-contain mt-5 aspect-square w-[25px]" alt="option" />
              <img loading="lazy" src={history} className="object-contain mt-5 aspect-square w-[25px]" alt="history" />
              <img loading="lazy" src={logoutICon} onClick={handleLogoutModal} className="object-contain mt-5 aspect-square w-[25px]" alt="logout" />
            </div>

            <div className="btn-lag">
              {lang === "en" ? (
                <p className="cursor-pointer text-center text-white text-sm rounded-xl border-[1px] border-solid border-white" onClick={() => changeAppLanguage("he")}>
                  {t("APP_LANGUAGE.HEBREW")}
                </p>
              ) : (
                <p className="cursor-pointer text-center text-white text-[12px] rounded-xl border-[1px] border-solid border-white" onClick={() => changeAppLanguage("en")}>
                  {t("APP_LANGUAGE.ENGLISH")}
                </p>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col justify-between primary-bg rounded-3xl pb-[25%] max-md:hidden max-md:pb-24 ipad-sidebar">
        <div className="flex flex-col items-center pt-1 ">
          <img loading="lazy" onClick={() => handleStudentList("")} src={home_icon} className="object-contain self-start aspect-square w-[74px] max-md:-mr-2.5" alt="site-icon" />

          <img loading="lazy" onClick={() => handleStudentList("")} src={home} className="object-contain mt-4 aspect-square w-[25px]" alt="home-icon" />
          <img loading="lazy" src={control} className="object-contain mt-5 aspect-square w-[25px]" alt="contr-icon" />
          <img loading="lazy" src={books} className="object-contain mt-5 aspect-square w-[25px]" alt="option" />
          <img loading="lazy" src={history} className="object-contain mt-5 aspect-square w-[25px]" alt="history" />
          <img loading="lazy" src={logoutICon} onClick={handleLogoutModal} className="object-contain mt-5 aspect-square w-[25px]" alt="logout" />
        </div>

        <div className="btn-lag">
          {lang === "en" ? (
            <p className="cursor-pointer text-center text-white text-sm rounded-xl border-[1px] border-solid border-white" onClick={() => changeAppLanguage("he")}>
              {t("APP_LANGUAGE.HEBREW")}
            </p>
          ) : (
            <p className="cursor-pointer text-center text-white text-[12px] rounded-xl border-[1px] border-solid border-white" onClick={() => changeAppLanguage("en")}>
              {t("APP_LANGUAGE.ENGLISH")}
            </p>
          )}
        </div>
      </div>

      {showLogoutModal && (
        <Alert title={i18n.t("LOG_OUT.MODAL_TITLE")} message={i18n.t("LOG_OUT.MODAL_MESSAGE")} actionBtn={i18n.t("LOG_OUT.LOGOUT_BTN")} handleSubmit={handleLogout} handleClose={closeLogoutModal} />
      )}
    </>
  );
}

export default SideBar;
