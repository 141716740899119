import React from "react";
import right from "../assets/icons/right.svg";
import cancel from "../assets/icons/cancel.svg";
import close from '../assets/icons/close.svg'
import { studentStatus } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { updateStudentStatus } from "../store/slices/students.slice";
import { useTranslation } from "react-i18next";
import { localStorageUtil } from "../utils/storage/localStorage.util";
import { localStorageConst } from "../data/constants/LocalStorage.const";

function StudentModal({ studentData, onClose, handleStudentStatus }) {
  // const { students } = useSelector((state) => state.students);
  
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <div className="absolute left-[400px] top-15 flex flex-col text-m font-bold text-black rounded-none max-w-[480px] h-[430px] center-pop">
      <div className="relative flex flex-col justify-center items-center px-[80px] pt-[120px] pb-[80px] w-full shadow-2xl bg-white bg-opacity-10 backdrop-blur-xl backdrop-sepia-0  rounded-[46px] border-white border-[2px]">
        <img src={close} alt="close" width={"40px"} className="absolute right-5 top-5 cursor-pointer" onClick={onClose} />
        <div className="bg-white rounded-xl shadow-sm w-[300px] relative box-sha">
          <img loading="lazy" src={studentData.img} alt="kid" width={"250px"} className="rounded-xl absolute  border-white border-[5px] img-ce" />

          <div className="Modal-text">
            <p className="text-sm ">
              <span>{t("HOME.STUDENT_NAME")}</span> : <span className="text-wrap">{studentData.name}</span>
            </p>
            {/* <p className="text-sm ">
              <span>{t("HOME.STUDENT_AGE")}</span> : <span className="text-wrap">{studentData.age}</span>
            </p>
            <p className="text-sm ">
              <span>{t("HOME.STUDENT_CLASS")}</span> : <span className="text-wrap">{studentData.class}</span>
            </p>
            <p className="text-sm ">
              <span>{t("HOME.STUDENT_GUARDIAN")}</span> : <span className="text-wrap">{studentData.guardian}</span>
            </p>
            <p className="text-sm ">
              <span>{t("HOME.STUDENT_EMERGENCY_CALL")}</span> : <span className="text-wrap">{studentData.emergency_call}</span>
            </p> */}
            <div className="py-1 btn-popup">
              {/* {!studentData.status && ( */}
              {/* <div className="flex">
                <button
                  onClick={() => handleStudentStatus(studentStatus.PRESENT, studentData.id)}
                  className="flex gap-1 px-[15px] text-m  leading-relaxed text-white whitespace-nowrap present-bg rounded-x2 max-md:px-5"
                >
                  <div>{t("HOME.PRESENT_BTN")}</div>
                  <img loading="lazy" src={right} alt="right" className="object-contain shrink-0 my-auto aspect-square w-[15px]" />
                </button>
                <button
                  onClick={() => handleStudentStatus(studentStatus.ABSENT, studentData.id)}
                  className="flex gap-1 px-[15px] text-m  leading-relaxed text-white whitespace-nowrap absent-bg rounded-x2 max-md:px-5"
                >
                  <div>{t("HOME.ABSENT_BTN")}</div>
                  <img loading="lazy" src={cancel} alt="cancel" className="object-contain shrink-0 my-auto aspect-square w-[15px]" />
                </button>
              </div> */}
              {/* )} */}

              {(!studentData.status || studentData.status === studentStatus.ABSENT) && (
                <button
                  className="flex gap-3.5 px-[58px] w-[100%] text-m font-bold leading-relaxed text-white whitespace-nowrap present-bg rounded-lg max-md:px-5"
                  onClick={() => handleStudentStatus(studentStatus.PRESENT, studentData.id)}
                >
                  <div>{t("HOME.PRESENT_BTN")}</div>
                  <img loading="lazy" src={right} alt="right" className="object-contain shrink-0 my-auto aspect-square w-[15px]" />
                </button>
              )}
              {studentData.status === studentStatus.PRESENT && (
                <button
                  className="flex gap-3.5 px-[58px] w-[100%] text-m font-bold leading-relaxed text-white whitespace-nowrap absent-bg rounded-lg max-md:px-5"
                  onClick={() => handleStudentStatus(studentStatus.ABSENT, studentData.id)}
                >
                  <div>{t("HOME.ABSENT_BTN")}</div>
                  <img loading="lazy" src={cancel} alt="cancel" className="object-contain shrink-0 my-auto aspect-square w-[15px]" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentModal;
