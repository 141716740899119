import { toast } from "react-toastify";
import { localStorageConst } from "../../data/constants/LocalStorage.const";
import { authAPI } from "../../services/auth.service";
import { localStorageUtil } from "../../utils/storage/localStorage.util";
import { loginError, loginRequest, loginSuccess, logoutError, logoutRequest, logoutSuccess, signupError, signupRequest, signupSuccess } from "../slices/auth.slice"
import { UrlMap } from "../../data/constants/URLMAP";

export const login = (payload,navigate) => {
    return async (dispatch) => {
        dispatch(loginRequest());

        await authAPI.login(payload).then((res) => {
            const { message, error, status, token } = res.data;
            if (status===1) {
                toast.success(message)
                localStorageUtil.setItem(localStorageConst.AUTH_TOKEN, payload.userToken);
                navigate('/')
                dispatch(loginSuccess({ message, token }))
            } else {
                toast.error(error)
                dispatch(loginError(error))
            }
        }).catch((error) => {
            toast.error(error)
            dispatch(loginError(error))
        })
    }
}

export const signup = (payload, navigate) => {
    return async (dispatch) => {
        dispatch(signupRequest());

        await authAPI.signup(payload).then((res) => {
            const { message, error, status } = res.data;
            
            if (status) {
                navigate(UrlMap.login)
                dispatch(signupSuccess(message))
            } else {
                toast.error(error)
                dispatch(signupError(error))
            }
        }).catch((error) => {
            toast.error(error)
            dispatch(signupError(error))
        })
    }
}

export const logout = () => {
    return async (dispatch) => {
        dispatch(logoutRequest());
        // await authAPI.logout().then((res) => {
        //     const { message, error, status } = res.data;
            // if (status) {
                dispatch(logoutSuccess('Succesfully logout'))
                localStorageUtil.removeItem(localStorageConst.AUTH_TOKEN)
            // } else {
            //     dispatch(logoutError(error))
            // }
        // }).catch((error) => {
        //     dispatch(logoutError(error))
        // })
    }
}