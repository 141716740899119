import React from 'react'
import { useLocation } from 'react-router-dom';
import ResetPassword from '../pages/auth/ResetPassword';
import VerifyEmail from '../pages/auth/VerifyEmail';

function AuthHandler() {

     const path = useLocation();

     const mode = path?.search.split("?mode=")[1].split("&oobCode=")[0];
     console.log(mode, "mode");
    return <>{mode==='resetPassword' ? <ResetPassword /> : <VerifyEmail/>}</>;
}

export default AuthHandler