import * as Yup from "yup";
import { passwordPattern } from "../../../data/constants/RegularExpressions";
import { emailPattern } from "../../../data/constants/RegularExpressions";
import i18n from "../../../i18n";
const LoginSchema = Yup.object().shape({
  email: Yup.string().email().matches(
    emailPattern,
    i18n.t("LOGIN.EMAIL_INVALID_ERROR")
  ).required(i18n.t("LOGIN.EMAIL_REQUIRED_ERROR")),
  password: Yup.string().matches(
    passwordPattern,
    i18n.t("LOGIN.PASSWORD_INVALID_ERROR")
  ).required(i18n.t("LOGIN.PASSWORD_REQUIRED_ERROR")),
});

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email().matches(
    emailPattern,
    i18n.t("SIGNUP.EMAIL_INVALID_ERROR")
  ).required(i18n.t("SIGNUP.EMAIL_REQUIRED_ERROR")),
  password: Yup.string().matches(
    passwordPattern,
    i18n.t("SIGNUP.PASSWORD_INVALID_ERROR")
  ).required(i18n.t("SIGNUP.PASSWORD_REQUIRED_ERROR")),
});

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().matches(
    emailPattern,
    i18n.t("FORGOT_PASSWORD.EMAIL_INVALID_ERROR")
  ).required(i18n.t("FORGOT_PASSWORD.EMAIL_REQUIRED_ERROR")),
});

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, i18n.t("RESET_PASSWORD.PASSWORD_MINIMUM_ERROR"))
    .matches(
      passwordPattern,
      i18n.t("RESET_PASSWORD.PASSWORD_PATTERN")
    )
    .required(i18n.t("RESET_PASSWORD.PASSWORD_REQUIRED_ERROR")),
  confirmPassword: Yup.string()
    .min(6)
    .matches(passwordPattern)
    .oneOf(
      [Yup.ref("newPassword")],
      ({ path }) => i18n.t("RESET_PASSWORD.CONFIRM_PASSWORD_MISMATCHED_ERROR")
    )
    .required(i18n.t("RESET_PASSWORD.CONFIRM_PASSWORD_REQUIRED_ERROR")),
});

export {
  LoginSchema,
  SignUpSchema,
  ForgotPasswordSchema,
  ResetPasswordSchema,
};
