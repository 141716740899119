import React, { useEffect, useState } from 'react'
import i18n from '../../../i18n';

function CountDownAlert({timeout,redirectOn}) {
    const [remaining, setRemaining] = useState(timeout);
    
useEffect(() => {
   function tick() {
     setRemaining(remaining - 1);
   }

   const countdown = setInterval(tick, 1000);
   
   if (remaining <= 0) {
       clearInterval(countdown);
    }
    
   return () => clearInterval(countdown);
 }, [remaining]);
    
  return (
    <div className="count-down primary-bg py-5 px-3 rounded-lg text-white">{`${i18n.t("REDIRECT_ALERT_MESSAGE1")} ${redirectOn === "/" ? i18n.t("PAGES.HOME") : i18n.t("PAGES.ABSENT")} ${i18n.t(
      "REDIRECT_ALERT_MESSAGE2"
    )} ${remaining}`}</div>
  );
}

export default CountDownAlert