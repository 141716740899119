import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import i18n from "../../i18n";
import { changeRTLLayout } from "../../store/slices/Layout/layoutSlice";
import { updateAppTitle } from "../../helper";
import { RouteTitles } from "../../constant";
import { ToastContainer } from "react-toastify";
import { localStorageUtil } from "../../utils/storage/localStorage.util";
import { localStorageConst } from "../../data/constants/LocalStorage.const";
import { UrlMap } from "../../data/constants/URLMAP";

function AuthLayout() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { authToken } = useSelector((state) => state.auth);

  const [isRTLMode, setIsRTLMode] = useState(false);

  const userInfo = localStorageUtil.getItem(localStorageConst.AUTH_TOKEN);

  // Set direction for LTR/RTL directions.
  useEffect(() => {
    // userInfo?.data && dispatch(setAuthUser(userInfo?.data));
    // userInfo?.token && dispatch(setAuthToken(userInfo?.token));
    const dir = ["he"].includes(i18n.resolvedLanguage) ? "rtl" : "ltr";
    const htmlElem = document.getElementsByTagName("html");
    const bodyElem = document.getElementsByTagName("body");

    // bodyElem && bodyElem[0].setAttribute("data-layout-size", "fluid");
    // bodyElem && bodyElem[0].setAttribute("data-topbar", "light");

    if (htmlElem && bodyElem && dir === "rtl") {
      htmlElem[0].setAttribute("dir", "rtl");
      bodyElem[0].style.textAlign = "right";
      bodyElem[0].classList.add("rtl-mode");
      setIsRTLMode(true);
    } else {
      htmlElem[0].setAttribute("dir", "");
      bodyElem[0].style.textAlign = "";
      bodyElem[0].classList.remove("rtl-mode");
      setIsRTLMode(false);
    }

    dispatch(changeRTLLayout({ isRtlLayout: dir === "rtl" }));
  }, []);

  // Update document title
  useEffect(() => {
    const matchPath = [];

    let routePath = location.pathname;

    const idx = matchPath.findIndex((v) => routePath.startsWith(v));
    if (idx !== -1) routePath = matchPath[idx] + "*";

    updateAppTitle(RouteTitles[routePath] || "404");
  }, [location]);

  useEffect(() => {
    const userInfo = localStorageUtil.getItem(localStorageConst.AUTH_TOKEN);
    const path = window.location.pathname
    const authPaths = [UrlMap.ForgotPassword, UrlMap.SignUp, UrlMap.ResetPassword]
    // console.log(!authPaths.includes(path), "authPaths.includes(path)");
    // console.log(path, "path");
    
    if (!authPaths.includes(path)) {
      
      if (userInfo) {
        navigate("/");
      } else {
        navigate(UrlMap.login);
      }
    }
  }, []);

  return (
    <div className="flex justify-center auth-bg items-center">
      <Outlet />
      <ToastContainer autoClose={3000} hideProgressBar newestOnTop closeOnClick theme="colored" pauseOnFocusLoss={false} rtl={isRTLMode} />
    </div>
  );
}

export default AuthLayout;
