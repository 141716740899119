import React from "react";
import google_icon from "../../assets/icons/icon_google.svg";
import facebook_icon from "../../assets/icons/icon_facebook.svg";
import { useFormik } from "formik";
import { SignUpSchema } from "./validation_schema/auth.schema";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/common/core/InputField";
import PasswordInputField from "../../components/common/core/PasswordInputField";
import InputBodyColsTwo from "../../components/common/core/InputBodyColsTwo";
import { useDispatch, useSelector } from "react-redux";
import { createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, sendEmailVerification } from "firebase/auth";
import con from "../../config/firebaseConfig";
import { FacebookAuthProvider } from "firebase/auth";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { changeAppLanguage } from "../../helper";
import { signup } from "../../store/middleware/auth.middleware";
import SubmitBtn from "../../components/common/SubmitBtn";
import { toast } from "react-toastify";
import { handleFacebook, handleGoogle } from "../../helper/socialLogin.helper";

function Signup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signUpLoading } = useSelector((state) => state.auth);
  const lang = i18n.resolvedLanguage;
  const fbprovider = new FacebookAuthProvider();
  const googleProvider = new GoogleAuthProvider();

  fbprovider.setCustomParameters({
    display: "popup",
  });

  const auth = getAuth(con);

  const handleSubmit = (values) => {
    const { email, password } = values;

    if (email && password) {
      
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          console.log(auth, "auth");
          console.log(userCredential, "userCredential");
          
          const user = userCredential.user;
          dispatch(signup({ userToken: user.accessToken }, navigate));

          if (!auth.currentUser.emailVerified) {
            await sendEmailVerification(auth.currentUser)
              .then(() => {
                toast.success(t("SIGNUP.VERIFICATION_MAIL_SENT"));
              })
              .catch((error) => {
                console.log(error, "error");
                toast.error(error);
              });
          }
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            toast.error(t("SIGNUP.USER_ALREADY_REGISTERED"));
          } else {
            toast.error(error.code);
          }
          console.log(error, "error");
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignUpSchema,
    onSubmit: handleSubmit,
  });

  const googleLogin = async () => {
     handleGoogle(auth, googleProvider, navigate, dispatch);
  };

  const handleFacebookLogin = async () => {
     handleFacebook(auth, fbprovider, navigate, dispatch);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };


  return (
    <div className=" backdrop-blur-xl backdrop-sepia-0 w-[350px] h-[480px] border-2 border-[orange] rounded-2xl">
      <div className="flex flex-col gap-3 p-7">
        <h1 className="text-white text-3xl text-center">{t("SIGNUP.SECTION_TITLE")}</h1>
        <div>
          <h1 className="text-white text-2xl font-normal ">{t("SIGNUP.SIGNUP")}</h1>
          <p className="text-white">{t("SIGNUP.SECTION_TEXT")}</p>
        </div>

        <InputBodyColsTwo>
          <InputField
            fieldType={"email"}
            fieldName={"email"}
            handleOnChange={formik.handleChange}
            values={formik.values.email}
            fieldPlaceholder={t("SIGNUP.EMAIL_PLACEHOLDER")}
            errMsg={formik.errors.email}
            onKeyPress={handleKeyPress}
          />

          <PasswordInputField
            fieldName={"password"}
            fieldPlaceholder={t("SIGNUP.PASSWORD_PLACEHOLDER")}
            handleOnChange={formik.handleChange}
            values={formik.values.password}
            errMsg={formik.errors.password}
            onKeyPress={handleKeyPress}
          />
        </InputBodyColsTwo>

        <div className="pt-2">
          <SubmitBtn btnText={t("SIGNUP.SIGNUP_BTN")} onSubmit={formik.handleSubmit} isLoading={signUpLoading} />
        </div>
        <div className="pt-5">
          <div className="relative">
            <hr className="primary-border w-[45%] absolute left-0" />
            <p className="text-black text-center absolute left-[48%] top-[-12px]">{t("LOGIN.OR")}</p>
            <hr className="primary-border w-[43%] absolute right-0" />
          </div>
          <div className="flex justify-center gap-3 p-4">
            <img src={google_icon} onClick={googleLogin} alt="google_icon" />
            <img src={facebook_icon} onClick={handleFacebookLogin} alt="facebook_icon" />
          </div>
        </div>
        <div className="w-[50%] self-center">
          {lang === "en" ? (
            <p className="cursor-pointer text-center text-black text-sm rounded-xl border-[1px] border-solid  border-[orange]" onClick={() => changeAppLanguage("he")}>
              {t("APP_LANGUAGE.HEBREW")}
            </p>
          ) : (
            <p className="cursor-pointer text-center text-black text-[12px] rounded-xl border-[1px] border-solid  border-[orange]" onClick={() => changeAppLanguage("en")}>
              {t("APP_LANGUAGE.ENGLISH")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Signup;
