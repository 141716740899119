/* eslint-disable react/prop-types */
import { useState } from "react";
import ErrorField from "./ErrorField";
import closed_eye from "../../../assets/icons/closed_eye.svg";
import eye2 from "../../../assets/icons/eye-2.svg";
import i18n from "../../../i18n";
function PasswordInputField({ infoNeeded = true, fieldId, fieldName, fieldLabel, fieldPlaceholder, isRequired = false, handleOnChange, handleOnBlur, values, errMsg, otherProps = {}, onKeyPress }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const errMsgField = errMsg ? <ErrorField errMsg={errMsg} /> : "";
  const { ref } = otherProps;
  const lang = i18n.resolvedLanguage;
  return (
    <div>
      <div className="relative">
        <input
          className={`shadow-inputShadow border-[1px] rounded-radius_4 leading-none py-3 w-full px-2 pl-4  text-font_14 ${errMsg ? "border-red-500" : "primary-border"}`}
          id={fieldId}
          name={fieldName}
          type={showPassword ? "text" : "password"}
          placeholder={fieldPlaceholder}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          value={values}
          ref={ref ?? null}
          onKeyDown={onKeyPress}
        />

        <img
          src={showPassword ? closed_eye : eye2}
          alt="eye"
          width={"30px"}
          height={"30px"}
          className={`absolute top-[8px] ${lang === "en" ? "right-[8px]" : "left-[8px]"} `}
          onClick={handleShowPassword}
        />
        <div className="absolute bottom-[-22px]">{errMsgField}</div>
      </div>
    </div>
  );
}
export default PasswordInputField;
