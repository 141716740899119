import { createSlice } from "@reduxjs/toolkit";
import { authSliceConst } from "../../data/constants/SliceConstants";
import { localStorageUtil } from "../../utils/storage/localStorage.util";
import { localStorageConst } from "../../data/constants/LocalStorage.const";

const initialState = {
    isAuthenticated: localStorageUtil.getItem(localStorageConst.AUTH_TOKEN) ? true : false,
    authToken: localStorageUtil.getItem(localStorageConst.AUTH_TOKEN),
    userInfo: localStorageUtil.getItem(localStorageConst.USER_INFO),

    loginLoading: false,
    loginMessage: '',
    loginError: '',

    signUpLoading: false,
    signUpMessage: '',
    signUpError: '',

    logOutLoading: false,
    logOutMessage: '',
    logOutError: '',
}

const auhtSlice = createSlice({
    name: authSliceConst.AUTH_SLICE,
    initialState,
    reducers: {
        login(state, action) {
            state.isAuthenticated = true;
            state.authToken = action.payload.token;
            state.userInfo = action.payload.data;
        },
        loginRequest(state) {
            state.loginLoading = true;
            state.loginMessage = '';
            state.loginError = '';
        },
        loginSuccess(state, action) {
            state.isAuthenticated = true;
            state.loginLoading = false;
            state.loginMessage = action.payload.message;
            state.authToken = action.payload.token;
            // state.userInfo = action.payload.data;
        },
        loginError(state, action) {
            state.loginLoading = false;
            state.loginError = action.payload;
        },

        signupRequest(state) {
            state.signUpLoading = true;
            state.signUpMessage = '';
            state.signUpError = '';
        },
        signupSuccess(state, action) {
            state.signUpLoading = false;
            state.signUpMessage = action.payload;
        },
        signupError(state, action) {
            state.signUpLoading = false;
            state.signUpError = action.payload;
        },

        logoutRequest(state) {
            state.logOutLoading = true;
            state.logOutMessage = '';
            state.logOutError = '';
        },
        logoutSuccess(state, action) {
            state.isAuthenticated = false;
            state.authToken = null;
            state.userInfo = null;
            state.logOutLoading = false;
            state.logOutMessage = action.payload;
        },
        logoutError(state, action) {
            state.logOutLoading = false;
            state.logOutError = action.payload;
        },

        resetLogOutState(state) {
            state.logOutLoading = false;
            state.logOutMessage = '';
            state.logOutError = '';
        },
        resetSignUpState(state) {
            state.signUpLoading = false;
            state.signUpMessage = '';
            state.signUpError = '';
        },
        resetLoginState(state) {
            state.loginLoading = false;
            state.loginMessage = '';
            state.loginError = '';
        }
    }
})

export const {
    loginRequest, loginSuccess, loginError,
    logoutRequest, logoutSuccess, logoutError,
    signupRequest, signupSuccess, signupError,
    resetLoginState,resetLogOutState,resetSignUpState
} = auhtSlice.actions

export default auhtSlice.reducer