import React from "react";
import google_icon from "../../assets/icons/icon_google.svg";
import facebook_icon from "../../assets/icons/icon_facebook.svg";
import { useFormik } from "formik";
import { LoginSchema } from "./validation_schema/auth.schema";
import { Link, useNavigate } from "react-router-dom";
import { UrlMap } from "../../data/constants/URLMAP";
import InputField from "../../components/common/core/InputField";
import PasswordInputField from "../../components/common/core/PasswordInputField";
import InputBodyColsTwo from "../../components/common/core/InputBodyColsTwo";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/middleware/auth.middleware";
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import con from "../../config/firebaseConfig";
import { FacebookAuthProvider } from "firebase/auth";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { changeAppLanguage } from "../../helper";
import SubmitBtn from "../../components/common/SubmitBtn";
import { toast } from "react-toastify";
import { handleFacebook, handleGoogle } from "../../helper/socialLogin.helper";

function Login() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginLoading } = useSelector((state) => state.auth);
  const lang = i18n.resolvedLanguage;
  const auth = getAuth(con);
  const fbprovider = new FacebookAuthProvider();
  const googleProvider = new GoogleAuthProvider();

  fbprovider.setCustomParameters({
    display: "popup",
  });

  const handleSubmit = (values) => {
    const { email, password } = values;

    if (email && password) {
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {
          console.log(userCredential, "userCredential");
          console.log(auth, "auth");

          const user = userCredential.user;
          dispatch(login({ userToken: user.accessToken }, navigate));
        })
        .catch((error) => {
          console.log(auth, "auth");
          const providers = auth?.currentUser?.providerData || [];

          if (providers.length > 0 && error.code === "auth/invalid-credential") {
            if (providers[0].providerId === "password") {
              toast.error(t("LOGIN.INVALID_CREDENCIALS"));
            } else if (providers.length === 1) {
              if (providers[0].providerId === "facebook.com") {
                toast.error(t("LOGIN.LOGIN_WITH_FACEBOOK"));
              } else if (providers[0].providerId === "google.com") {
                toast.error(t("LOGIN.LOGIN_WITH_GOOGLE"));
              }
            } else if (providers.length === 2) {
              toast.error(t("LOGIN.LOGIN_WITH_ANY_ONE"));
            }
          } else {
            if (error.code === "auth/invalid-credential") {
              toast.error(t("LOGIN.INVALID_CREDENCIALS"));
            } else {
              toast.error(error);
            }
          }
          console.log(error, "error");
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
  });


  const handleFacebookLogin = async () => {
    handleFacebook(auth,fbprovider,navigate,dispatch);
  };

  const googleLogin = async () => {
    handleGoogle(auth,googleProvider,navigate,dispatch)
  };

  const handleLink = () => {
    navigate("/signup");
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
     formik.handleSubmit()
   }
  }

  return (
    <div className=" backdrop-blur-xl backdrop-sepia-0 w-[350px] h-[570px] border-2 border-[orange] rounded-2xl">
      <div className="flex flex-col gap-3 p-7">
        <h1 className="text-white text-3xl text-center">{t("LOGIN.SECTION_TITLE")}</h1>
        <div>
          <h1 className="text-white text-2xl font-normal ">{t("LOGIN.LOGIN")}</h1>
          <p className="text-white">{t("LOGIN.SECTION_TEXT")}</p>
        </div>
        <InputBodyColsTwo>
          <InputField
            fieldType={"email"}
            fieldName={"email"}
            handleOnChange={formik.handleChange}
            values={formik.values.email}
            fieldPlaceholder={t("LOGIN.EMAIL_PLACEHOLDER")}
            errMsg={formik.errors.email}
            onKeyPress={handleKeyPress}
          />

          <PasswordInputField
            fieldName={"password"}
            fieldPlaceholder={t("LOGIN.PASSWORD_PLACEHOLDER")}
            handleOnChange={formik.handleChange}
            values={formik.values.password}
            errMsg={formik.errors.password}
            onKeyPress={handleKeyPress}
          />

          <div className="flex flex-row gap-2">
            <input type="checkbox" name="remember" />
            <p className="text-black">{t("LOGIN.REMEMBER_ME")}</p>
          </div>
        </InputBodyColsTwo>
        <div>
          <SubmitBtn btnText={t("LOGIN.LOGIN_BTN")} onSubmit={formik.handleSubmit} isLoading={loginLoading} />
        </div>
        <div className="p-1">
          <Link to={UrlMap.ForgotPassword}>
            <p className="text-black text-center">{t("LOGIN.FORGOT_PASSWORD")}</p>
          </Link>
        </div>
        <div>
          <div className="relative">
            <hr className=" primary-border w-[45%] absolute left-0" />
            <p className="text-black text-center absolute left-[48%] top-[-12px]">{t("LOGIN.OR")}</p>
            <hr className=" primary-border w-[43%] absolute right-0" />
          </div>

          <div className="flex justify-center gap-3 p-4">
            <img src={google_icon} onClick={googleLogin} alt="google_icon" />
            <img src={facebook_icon} onClick={handleFacebookLogin} alt="facebook_icon" />
          </div>

          <p className="text-black text-center cursor-pointer" onClick={() => handleLink()}>
            {t("LOGIN.SIGNUP_TEXT")}
          </p>
        </div>
        <div className="w-[50%] self-center">
          {lang === "en" ? (
            <p className="cursor-pointer text-center text-black text-sm rounded-xl border-[1px] border-solid  border-[orange]" onClick={() => changeAppLanguage("he")}>
              {t("APP_LANGUAGE.HEBREW")}
            </p>
          ) : (
            <p className="cursor-pointer text-center text-black text-[12px] rounded-xl border-[1px] border-solid  border-[orange]" onClick={() => changeAppLanguage("en")}>
              {t("APP_LANGUAGE.ENGLISH")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
