import React, { useEffect } from "react";
import i18n from "../../i18n";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { UrlMap } from "../../data/constants/URLMAP";

function VerifyEmail() {
  const urlString = useLocation()?.search?.split("&");
  const navigate = useNavigate();

  const code = urlString[1]?.split("oobCode=")[1];
  const apikey = urlString[2]?.split("apiKey=")[1];

  const handleSubmit = () => {
    const obj = {
      oobCode: code,
    };

    axios
      .post(`https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=${apikey}`, obj)
      .then(() => {
        toast.success(i18n.t("VERIFY_EMAIL.SUCCESS_VERIFY_EMAIL"));
        navigate(UrlMap.login);
      })
        .catch((error) => {
          const {response} = error
        console.log(error, "error");
        toast.error(response.data.error.message);
      });
  };

  return (
    <div className=" backdrop-blur-xl backdrop-sepia-0 w-[380px] h-auto border-2 border-[orange] rounded-2xl ">
      <div className="flex flex-col gap-3 p-10">
        <h1 className="text-white text-4xl text-center">{i18n.t("RESET_PASSWORD.SECTION_TITLE")}</h1>
        <div>
          <h1 className=" text-2xl font-normal ">{i18n.t("VERIFY_EMAIL.VERIFY_EMAIL_TEXT")}</h1>
        </div>

        <div className="pt-4">
          <button type="button" className="auth-btn" onClick={handleSubmit}>
            {i18n.t("VERIFY_EMAIL.VERIFY_EMAIL_BTN")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
