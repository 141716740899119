function ErrorField({ errMsg }) {
  return (
    <>
      {errMsg && (
        <div className="mt-2" style={{ color: "red", fontSize: "small" }}>
          {errMsg}
        </div>
      )}
    </>
  );
}
export default ErrorField;
