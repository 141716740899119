import React, { useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import StudentInfo from "./StudentInfo";
import { localStorageUtil } from "../utils/storage/localStorage.util";
import { localStorageConst } from "../data/constants/LocalStorage.const";
import StudentModal from "./StudentModal";
import { studentStatus } from "../constant";

function StudentListing() {
  const search = useOutletContext();
  const pathname = useLocation().pathname;
  const path = pathname.split("/")[1];
  const dateString = new Date().toLocaleDateString();
  const dataObj = localStorageUtil.getItem(dateString);
  const dataArray = path === studentStatus.PRESENT ? dataObj[localStorageConst.PRESENT_STUDENTS] : dataObj[localStorageConst.ABSENT_STUDENTS];

  const [studentData, setStudentData] = useState({ img: "", name: "", id: "", status: null, class: "", emergency_call: "", guardian: "", age: "" });
  const [studentsData, setStudentsData] = useState([]);

  const handleStudentData = (val) => {
    setStudentData(val);
  };

  const handleClose = () => {
    setStudentData({ img: "", name: "", id: "", status: null });
  };

  const handleStudentStatus = (val, id) => {
    const dataObj = localStorageUtil.getItem(dateString);
    const all = dataObj["all"];
    // const entry = [...all].findIndex((val) => val.id === id);

    // if (all[entry]) {
    //   let newArr = [...all].filter((val) => val.id !== all[entry].id);

    //   // setStudentsData(newArr);
    //   dataObj["all"] = newArr;
    //   localStorageUtil.setItem(dateString, dataObj);
    // }

    let present = dataObj[localStorageConst.PRESENT_STUDENTS];
    let absent = dataObj[localStorageConst.ABSENT_STUDENTS];

    if (val === studentStatus.PRESENT) {
      let absentEntry = [...absent].find((val) => val.id === id);

      if (absentEntry) {
        let newArr = [...absent].filter((val) => val.id !== absentEntry.id);
        dataObj[localStorageConst.ABSENT_STUDENTS] = newArr;
        // localStorageUtil.setItem(localStorageConst.ABSENT_STUDENTS, newArr);
        setStudentsData(newArr);
        absentEntry.status = studentStatus.PRESENT;
        dataObj[localStorageConst.PRESENT_STUDENTS] = [...present, absentEntry];
        // localStorageUtil.setItem(localStorageConst.PRESENT_STUDENTS, [...present, absentEntry]);
        localStorageUtil.setItem(dateString, dataObj);
      } else {
        absentEntry.status = studentStatus.PRESENT;
        const newArr = [...present, absentEntry];
        dataObj[localStorageConst.PRESENT_STUDENTS] = newArr;
        localStorageUtil.setItem(dateString, dataObj);
        // localStorageUtil.setItem(localStorageConst.PRESENT_STUDENTS, newArr);
      }
    } else {
      let presentEntry = [...present].find((val) => val.id === id);
      if (presentEntry) {
        let newArr = [...present].filter((val) => val.id !== presentEntry.id);
        dataObj[localStorageConst.PRESENT_STUDENTS] = newArr;
        // localStorageUtil.setItem(localStorageConst.PRESENT_STUDENTS, newArr);
        setStudentsData(newArr);
        presentEntry.status = studentStatus.ABSENT;
        dataObj[localStorageConst.ABSENT_STUDENTS] = [...absent, presentEntry];
        // localStorageUtil.setItem(localStorageConst.ABSENT_STUDENTS, [...absent, presentEntry]);
        localStorageUtil.setItem(dateString, dataObj);
      } else {
        presentEntry.status = studentStatus.ABSENT;
        const newArr = [...absent, presentEntry];
        dataObj[localStorageConst.ABSENT_STUDENTS] = newArr;
        localStorageUtil.setItem(dateString, dataObj);
        // localStorageUtil.setItem(localStorageConst.ABSENT_STUDENTS, newArr);
      }
    }

    handleClose();
  };

  return (
    <>
      {dataArray

        ?.filter((val) => val.name.toLowerCase().includes(search.toLowerCase()))
        .map((value) => (
          <StudentInfo key={value.id} studentDetails={value} handleClick={handleStudentData} studentsAttendance={path} />
        ))}

      {studentData.name && <StudentModal id={studentData.id} studentData={studentData} onClose={handleClose} handleStudentStatus={handleStudentStatus} />}
    </>
  );
}

export default StudentListing;
