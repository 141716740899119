import { get, post } from "../config/axiosConfig";


const URI = '/auth';

const login = (payload) => {
    const url = `${URI}/login`
    return post(url,payload)
}

const logout = () => {
    const url = `${URI}/logout`
    return get(url)
}

const signup = (payload) => {
    const url = `${URI}/register`;
    return post(url, payload);
}

const forgotPassword = (payload) => {
    const url = `${URI}/forgot-password`;
    return post(url,payload)
}

const resetPassword = (payload) => {
    const url = `${URI}/reset-password`;
    return post(url,payload)
}

export const authAPI = {
    login,logout,signup,forgotPassword,resetPassword
}