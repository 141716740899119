import AuthLayout from "../../components/layouts/AuthLayout";
import Login from "../../pages/auth/Login";
import ForgotPassword from "../../pages/auth/ForgotPassword";
import ResetPassword from "../../pages/auth/ResetPassword";
import { UrlMap } from "../../data/constants/URLMAP";
import Signup from "../../pages/auth/Signup";
import AuthHandler from "../../components/AuthHandler";

export const routes = [
    {
        element: <AuthLayout />,
        children: [
            {
                path: UrlMap.login,
                element: <Login />,
            },
            {
                path: UrlMap.SignUp,
                element: <Signup />,
            },
            {
                path: UrlMap.ForgotPassword,
                element: <ForgotPassword />,
            },
            {
                path: UrlMap.ResetPassword,
                element: <AuthHandler />,
            },
        ]
    }
];
