import { getAdditionalUserInfo, linkWithCredential, sendEmailVerification, signInWithPopup } from "firebase/auth";
import { authAPI } from "../services/auth.service";
import { localStorageUtil } from "../utils/storage/localStorage.util";
import { localStorageConst } from "../data/constants/LocalStorage.const";
import { toast } from "react-toastify";
import i18n from "../i18n";
import { login } from "../store/middleware/auth.middleware";

const helperForFacebook = async (email, cred, navigate, dispatch,auth) => {
    await linkWithCredential(email, cred)
        .then(({ user }) => {
            authAPI
                .signup({ userToken: user.accessToken })
                .then((res) => {
                    const { error, status } = res.data;

                    if (status === 1) {
                        authAPI
                            .login({ userToken: user.accessToken })
                            .then(async (res) => {
                                const { message, error, status, token } = res.data;
                                if (status === 1) {
                                    localStorageUtil.setItem(localStorageConst.AUTH_TOKEN, user.accessToken);
                                    navigate("/");
                                    toast.success(message);
                                } else {
                                    if (!user.emailVerified) {
                                        await sendEmailVerification(auth.currentUser)
                                            .then((val) => {
                                                console.log(val, "val");
                                                toast.success(i18n.t("SIGNUP.FACEBOOK_VERIFICATION_MAIL_SENT"));
                                            })
                                            .catch((error) => {
                                                console.log(error, "error");
                                                toast.error(error);
                                            });
                                    } else {
                                        dispatch(login({ userToken: user.accessToken }));
                                    }
                                }
                            })
                            .catch((error) => toast.error(error));
                    } else {
                        toast.error(error);
                    }
                })
                .catch((error) => toast.error(error));
        })
        .catch((error) => {
            console.log(error, "error");
        });
};

const handleFacebook = async (auth, fbprovider, navigate, dispatch) => {
    try {
        const result = await signInWithPopup(auth, fbprovider);

        const user = result.user;
        const userInfo = getAdditionalUserInfo(result);

        if (userInfo.isNewUser) {
            authAPI
                .signup({ userToken: user.accessToken })
                .then((res) => {
                    const { message, error, status, token } = res.data;

                    if (status === 1) {
                        authAPI
                            .login({ userToken: user.accessToken })
                            .then(async (res) => {
                                const { message, error, status, token } = res.data;
                                if (status === 1) {
                                    localStorageUtil.setItem(localStorageConst.AUTH_TOKEN, user.accessToken);
                                    navigate("/");
                                    toast.success(message);
                                } else {
                                    if (!user.emailVerified) {
                                        await sendEmailVerification(auth.currentUser)
                                            .then(() => {
                                                toast.success(i18n.t("SIGNUP.FACEBOOK_VERIFICATION_MAIL_SENT"));
                                            })
                                            .catch((error) => {
                                                console.log(error, "error");
                                                toast.error(error);
                                            });
                                    } else if (error) {
                                        toast.error(error);
                                    } else {
                                        dispatch(login({ userToken: user.accessToken }, navigate));
                                    }
                                }
                            })
                            .catch((error) => toast.error(error));
                    }
                })
                .catch((error) => toast.error(error));
        } else {
            if (!user.emailVerified) {
                await sendEmailVerification(auth.currentUser)
                    .then((val) => {
                        console.log(val, "val");
                        toast.success(i18n.t("SIGNUP.FACEBOOK_VERIFICATION_MAIL_SENT"));
                    })
                    .catch((error) => {
                        console.log(error, "error");
                        toast.error(error);
                    });
            } else {
                dispatch(login({ userToken: user.accessToken }, navigate));
            }
        }
    } catch (error) {
        if (error.code === "auth/account-exists-with-different-credential") {
            const credential = fbprovider.credential(error.customData._tokenResponse.oauthAccessToken);
            helperForFacebook(auth.currentUser, credential,navigate,dispatch,auth);
        } else {
            toast.error(error);
        }
    }
};

const handleGoogle = async (auth, googleProvider, navigate, dispatch) => {
    signInWithPopup(auth, googleProvider)
        .then(async (result) => {
            const user = result.user;
            const userInfo = getAdditionalUserInfo(result);
            console.log(userInfo, "userInfo");
            console.log(result, "result");

            if (userInfo.isNewUser) {
                authAPI
                    .signup({ userToken: user.accessToken })
                    .then((res) => {
                        const { message, error, status, token } = res.data;

                        if (status === 1) {
                            authAPI
                                .login({ userToken: user.accessToken })
                                .then((res) => {
                                    const { message, error, status, token } = res.data;
                                    if (status === 1) {
                                        localStorageUtil.setItem(localStorageConst.AUTH_TOKEN, user.accessToken);
                                        navigate("/");
                                        toast.success(message);
                                    } else {
                                        toast.error(error);
                                    }
                                })
                                .catch((error) => toast.error(error));
                        } else {
                            toast.error(error)
                        }
                    })
                    .catch((error) => toast.error(error));
            } else {
                // debugger
                // const credential = googleProvider.credentialFromResult(result);
                // await linkWithCredential(auth.currentUser, credential)
                //   .then(({ user }) => {
                //     authAPI
                //       .login({ userToken: user.accessToken })
                //       .then(async (res) => {
                //         const { message, error, status, token } = res.data;
                //         if (status === 1) {
                //           localStorageUtil.setItem(localStorageConst.AUTH_TOKEN, user.accessToken);
                //           navigate("/");
                //           toast.success(message);
                //         } else {
                //           if (!user.emailVerified) {
                //             await sendEmailVerification(auth.currentUser)
                //               .then((val) => {
                //                 console.log(val, "val");
                //                 toast.success(t("SIGNUP.FACEBOOK_VERIFICATION_MAIL_SENT"));
                //               })
                //               .catch((error) => {
                //                 console.log(error, "error");
                //                 toast.error(error);
                //               });
                //           } else {
                //             dispatch(login({ userToken: user.accessToken }));
                //           }
                //         }
                //       })
                //       .catch((error) => toast.error(error));
                //   })
                //   .catch((error) => {
                //     console.log(error, "error");
                //   });
                dispatch(login({ userToken: user.accessToken }, navigate));
            }
        })
        .catch((error) => {
            console.log(error, "error");
            toast.error(error);
        });
};
export { handleFacebook, handleGoogle }