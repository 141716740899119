import { PageName } from "../enums/PageName.ts";
export const UrlMap = {
    /*Default page start ##### */
    [PageName.Home]: "/",
    [PageName.Present]: "/present",
    [PageName.Absent]: "/absent",
    /*Default page end ##### */

    /*Auth pages start #####*/
    [PageName.Login]: "/login",
    [PageName.SignUp]: "/signup",
    [PageName.ForgotPassword]: "/forgot-password",
    [PageName.ResetPassword]: "/auth/",
    /*Auth pages end #####*/
};
