import React, { useEffect, useState } from "react";
import Navbar from "./common/core/Navbar";
import StudentInfo from "./StudentInfo";
import SideBar from "./common/core/Sidebar";
import StudentModal from "./StudentModal";
import { localStorageUtil } from "../utils/storage/localStorage.util";
import { localStorageConst } from "../data/constants/LocalStorage.const";
import { studentArray } from "../constant/studentArray";
import { studentStatus } from "../constant";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UrlMap } from "../data/constants/URLMAP";
import CountDownAlert from "./common/core/CountDownAlert";

function StudentCard() {
  // const { students } = useSelector((state) => state.students);

  const navigate = useNavigate();
  const dateString = new Date().toLocaleDateString();
  const students = localStorageUtil.getItem(dateString);
  const pathname = useLocation().pathname;
  let timeout = 7000;
  const [studentsData, setStudentsData] = useState([]);

  const removeExpiredValues = () => {
    for (let val of Object.keys(localStorage)) {
      if (localStorage[val].includes("all")) {
        const obj = JSON.parse(localStorage[val]);
        const oneDay = 24 * 60 * 60 * 1000;
        const dateDifference = Math.round((new Date(dateString) - new Date(obj.expiry)) / oneDay);

        if (dateDifference === 7) {
          localStorage.removeItem(val);
        }
      }
    }
  };

  if (!students) {
    const obj = {
      all: studentArray,
      [localStorageConst.ABSENT_STUDENTS]: [],
      [localStorageConst.PRESENT_STUDENTS]: [],
      expiry: new Date(new Date().setDate(new Date().getDate() + 7)),
    };
    localStorageUtil.setItem(dateString, obj);
  }

  useEffect(() => {
    const students = getStudentDataFromLocalStorage();
    removeExpiredValues();
    if (studentsData?.length === 0) {
      setStudentsData(students);
    }
  }, []);
  
  const [studentData, setStudentData] = useState({ img: "", name: "", id: "", status: null, class: "", emergency_call: "", guardian: "", age: "" });
  const [search, setSearch] = useState("");
  const [showCountdown, setShowCountDown] = useState({show:false,rediretOn:'/'});
  

  const getStudentDataFromLocalStorage = (type = "all") => {
    const dataArray = localStorageUtil.getItem(dateString);

    return dataArray[type];
  };

  const absentAllStudents = () => {
    const dataObj = localStorageUtil.getItem(dateString);
    const students = getStudentDataFromLocalStorage();
    const absentStudents = getStudentDataFromLocalStorage(localStorageConst.ABSENT_STUDENTS);

    [...students].forEach((val) => {
      val["status"] = studentStatus.ABSENT;
    });

    dataObj["all"] = [];
    dataObj[localStorageConst.ABSENT_STUDENTS] = [...absentStudents, ...students];
    localStorageUtil.setItem(dateString, dataObj);

    setStudentsData([]);
    navigate(UrlMap.Absent);
  };

  useEffect(() => {
    const students = getStudentDataFromLocalStorage();

    setShowCountDown({ show: false, rediretOn: '/' });
    if (pathname === "/" || pathname === UrlMap.Present || pathname === UrlMap.Absent) {
      const checkTime = () => {
        const date = new Date();
        const israelTimeZone = "Asia/Jerusalem";
        const israelTime = date.toLocaleTimeString("en-US", {
          timeZone: israelTimeZone,
          hour12: false,
        });
        const time = israelTime.split(":")[0];

        if (time >= 9 && (pathname === "/" || pathname === UrlMap.Present)) {
          if (students?.length > 0) {
            absentAllStudents();
          } else if (students?.length === 0) {
            setShowCountDown({ show: true, rediretOn: UrlMap.Absent });
          }
        } else if (time < 9 && (pathname === UrlMap.Present || pathname === UrlMap.Absent)) {
          setShowCountDown({ show: true, rediretOn: "/" });
        }
      };

      const timeInterval = setInterval(() => {
        checkTime();
      }, 1000);

      return () => clearInterval(timeInterval);
    }
  }, [pathname]);

  useEffect(() => {
    if (showCountdown.show) {
      
      setTimeout(() => {
        const storeRedirectUrl = showCountdown.rediretOn;
        setShowCountDown({ show: false, rediretOn: "/" });
        navigate(storeRedirectUrl);
      }, timeout);
    }
  }, [showCountdown.show]);

  const handleStudentData = (val) => {
    setStudentData(val);
  };

  const handleClose = () => {
    setStudentData({ img: "", name: "", id: "", status: null });
  };

  const handleStudentList = (val) => {
    navigate(`/${val}`);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const handleStudentStatus = (val, id) => {
    const dataObj = localStorageUtil.getItem(dateString);
    const all = dataObj["all"];
    const entry = [...all].findIndex((val) => val.id === id);

    if (all[entry]) {
      let newArr = [...all].filter((val) => val.id !== all[entry].id);

      setStudentsData(newArr);
      dataObj["all"] = newArr;
      localStorageUtil.setItem(dateString, dataObj);
    }

    let present = dataObj[localStorageConst.PRESENT_STUDENTS];
    let absent = dataObj[localStorageConst.ABSENT_STUDENTS];

    if (val === studentStatus.PRESENT) {
      let absentEntry = [...absent].find((val) => val.id === id);

      if (absentEntry) {
        let newArr = [...absent].filter((val) => val.id !== absentEntry.id);
        dataObj[localStorageConst.ABSENT_STUDENTS] = newArr;
        // localStorageUtil.setItem(localStorageConst.ABSENT_STUDENTS, newArr);
        setStudentsData(newArr);
        absentEntry.status = studentStatus.PRESENT;
        dataObj[localStorageConst.PRESENT_STUDENTS] = [...present, absentEntry];
        // localStorageUtil.setItem(localStorageConst.PRESENT_STUDENTS, [...present, absentEntry]);
        localStorageUtil.setItem(dateString, dataObj);
      } else {
        all[entry].status = studentStatus.PRESENT;
        const newArr = [...present, all[entry]];
        dataObj[localStorageConst.PRESENT_STUDENTS] = newArr;
        localStorageUtil.setItem(dateString, dataObj);
        // localStorageUtil.setItem(localStorageConst.PRESENT_STUDENTS, newArr);
      }
    } else {
      let presentEntry = [...present].find((val) => val.id === id);
      if (presentEntry) {
        let newArr = [...present].filter((val) => val.id !== presentEntry.id);
        dataObj[localStorageConst.PRESENT_STUDENTS] = newArr;
        // localStorageUtil.setItem(localStorageConst.PRESENT_STUDENTS, newArr);
        setStudentsData(newArr);
        presentEntry.status = studentStatus.ABSENT;
        dataObj[localStorageConst.PRESENT_STUDENTS] = [...absent, presentEntry];
        // localStorageUtil.setItem(localStorageConst.ABSENT_STUDENTS, [...absent, presentEntry]);
        localStorageUtil.setItem(dateString, dataObj);
      } else {
        all[entry].status = studentStatus.ABSENT;
        const newArr = [...absent, all[entry]];
        dataObj[localStorageConst.PRESENT_STUDENTS] = newArr;
        localStorageUtil.setItem(dateString, dataObj);
        // localStorageUtil.setItem(localStorageConst.ABSENT_STUDENTS, newArr);
      }
    }

    handleClose();
  };

  return (
    <div className="flex overflow-hidden flex-col bg-white ">
      <div className="flex flex-wrap gap-6 p-5 w-full bg-indigo-50 max-md:max-w-full">
        <div className="flex flex-col grow shrink-0 basis-0 w-fit max-md:max-w-full">
          <Navbar handleStudentList={handleStudentList} handleSearch={handleSearch} />
          <div className="flex flex-row mt-5 font-extrabold rounded-3xl max-md:max-w-full">
            <SideBar handleStudentList={handleStudentList} />
            <div className="flex  flex-col px-7 pt-11 pb-28 w-full rounded-none min-h-[929px] max-md:px-5 max-md:pb-24 max-md:max-w-full bg-[#EFFAFC] b-right">
              <div className="flex  flex-wrap gap-4">
                {(pathname === UrlMap.Absent || pathname === UrlMap.Present) && <Outlet context={search} />}

                {pathname === "/" &&
                  studentsData
                    ?.filter((val) => val.name.toLowerCase().includes(search.toLowerCase()))
                    ?.map((value) => <StudentInfo key={value.id} studentDetails={value} handleClick={handleStudentData} />)}
              </div>
            </div>

            {studentData.name && <StudentModal id={studentData.id} studentData={studentData} onClose={handleClose} handleStudentStatus={handleStudentStatus} />}

            {showCountdown.show && <CountDownAlert timeout={timeout / 1000} redirectOn={showCountdown.rediretOn} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentCard;
